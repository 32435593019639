import React from 'react';
import { graphql } from 'gatsby';
import { PageWithSubNav, ItemsListIndexPage } from 'docComponents';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav pageType="design" title="Patterns" subnav="patterns">
      <ItemsListIndexPage
        data={data}
        tierOnePath="words"
        listType="word"
        listLayout="2"
      />
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query patternsIndexQuery($tierOne: String = "Patterns") {
    ...indexList
  }
`;
